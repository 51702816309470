import { Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import { useRouter } from "next/router";

export default function ConsentModal({ state, setCookie }) {
  const [isOpen, setIsOpen] = useState(state);
  const router = useRouter();

  const closeModal = () => {
    setCookie("cookieConsent", "true");
    setIsOpen(false);
  };

  const translations = {
    nl: "Door onze website te gebruiken gaat u akkoord met onze ",
    en: "By continuing to use our website you agree to our ",
    fr: "En utilisant notre site web, vous acceptez notre ",
  };
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed bottom-0 left-0 right-0 z-20 flex w-full  flex-col items-center space-y-4 px-4 py-6 sm:items-end sm:p-6"
    >
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-ifixers p-4 shadow-lg">
          <div className="flex items-start">
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-base font-normal text-white">
                {translations[router.locale]}
                <Link href="/privacy">
                  <a className="font-medium underline">Cookie policy</a>
                </Link>
              </p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                className="inline-flex rounded-md  text-white hover:text-gray-200 focus:outline-none "
                onClick={() => {
                  closeModal();
                }}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
