const data = {
  en: {
    id: 2,
    locale: "en",
    published_at: "2021-12-10T14:39:50.453Z",
    created_at: "2021-12-10T14:39:48.562Z",
    updated_at: "2022-04-12T15:39:27.966Z",
    footer: [
      {
        __component: "menu.section",
        id: 2,
        label: "Our repairs",
        links: [
          {
            id: 2,
            label: "Smartphones",
            href: "/repairs/smartphones",
            description: null,
            locale: "en",
            published_at: "2021-12-10T14:35:42.920Z",
            created_at: "2021-12-10T14:35:40.303Z",
            updated_at: "2022-01-05T21:45:43.487Z",
            main_href: "/repairs/smartphones",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 7,
            label: "Tablets",
            href: "/repairs/tablets",
            description: null,
            locale: "en",
            published_at: "2021-12-20T16:23:41.350Z",
            created_at: "2021-12-20T16:23:37.685Z",
            updated_at: "2022-03-30T08:53:16.982Z",
            main_href: "/repairs/tablets",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 9,
            label: "Computers",
            href: "/computers",
            description: "",
            locale: "en",
            published_at: "2021-12-20T16:24:45.106Z",
            created_at: "2021-12-20T16:24:42.986Z",
            updated_at: "2022-01-28T09:56:18.161Z",
            main_href: "/computers",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 48,
            label: "Game consoles",
            href: "/repairs/gameconsoles",
            description: null,
            locale: "en",
            published_at: "2022-02-14T16:34:30.322Z",
            created_at: "2022-02-14T16:34:27.614Z",
            updated_at: "2022-04-12T11:37:22.248Z",
            main_href: "/repairs/gameconsoles",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
              "</svg>",
            showIsNewTag: true,
          },
        ],
      },
      {
        __component: "menu.section",
        id: 10,
        label: "Repair",
        links: [
          {
            id: 20,
            label: "Status",
            href: "/status",
            description: "View the status of your repair",
            locale: "en",
            published_at: "2021-12-20T17:02:33.449Z",
            created_at: "2021-12-20T17:02:27.178Z",
            updated_at: "2021-12-20T17:02:33.487Z",
            main_href: "/status",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 29,
            label: "Send to us",
            href: "/send-to-us",
            description: "Send your device to us",
            locale: "en",
            published_at: "2021-12-23T14:12:08.270Z",
            created_at: "2021-12-23T14:12:05.778Z",
            updated_at: "2021-12-23T14:12:08.351Z",
            main_href: "/send-to-us",
            icon: null,
            showIsNewTag: null,
          },
          {
            id: 32,
            label: "Pickup",
            href: "/pickup",
            description: "Let us pickup your device",
            locale: "en",
            published_at: "2021-12-23T14:13:44.549Z",
            created_at: "2021-12-23T14:13:42.945Z",
            updated_at: "2021-12-23T14:13:44.573Z",
            main_href: "/pickup",
            icon: null,
            showIsNewTag: null,
          },
          {
            id: 35,
            label: "Visit us",
            href: "/visit-us",
            description: "Visit us at our shop in Leuven",
            locale: "en",
            published_at: "2021-12-23T14:16:06.064Z",
            created_at: "2021-12-23T14:16:03.657Z",
            updated_at: "2021-12-23T14:16:06.131Z",
            main_href: "/visit-us",
            icon: null,
            showIsNewTag: null,
          },
          {
            id: 38,
            label: "Request repair",
            href: "/checkout",
            description: null,
            locale: "en",
            published_at: "2022-01-24T15:30:06.648Z",
            created_at: "2022-01-24T15:30:04.754Z",
            updated_at: "2022-01-24T15:30:06.674Z",
            main_href: "/checkout",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
        ],
      },
      {
        __component: "menu.section",
        id: 11,
        label: "Company",
        links: [
          {
            id: 23,
            label: "Contact",
            href: "/contact",
            description: "Talk to us",
            locale: "en",
            published_at: "2021-12-20T17:03:53.421Z",
            created_at: "2021-12-20T17:03:51.745Z",
            updated_at: "2021-12-20T17:03:53.474Z",
            main_href: "/contact",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 51,
            label: "B2B",
            href: "/b2b",
            description: null,
            locale: "en",
            published_at: "2022-02-21T15:36:18.154Z",
            created_at: "2022-02-21T15:36:16.292Z",
            updated_at: "2022-02-21T15:36:18.194Z",
            main_href: "/b2b",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
        ],
      },
    ],
    localizations: [
      { id: 1, locale: "nl", published_at: "2021-12-10T14:39:30.271Z" },
      { id: 3, locale: "fr", published_at: "2021-12-10T14:40:04.686Z" },
    ],
  },
  fr: {
    id: 3,
    locale: "fr",
    published_at: "2021-12-10T14:40:04.686Z",
    created_at: "2021-12-10T14:40:02.784Z",
    updated_at: "2022-04-12T15:42:21.959Z",
    footer: [
      {
        __component: "menu.section",
        id: 6,
        label: "Notre offres",
        links: [
          {
            id: 3,
            label: "Smartphones",
            href: "/réparations/smartphones",
            description: null,
            locale: "fr",
            published_at: "2021-12-10T14:39:08.574Z",
            created_at: "2021-12-10T14:36:56.521Z",
            updated_at: "2022-03-30T08:53:37.487Z",
            main_href: "/repairs/smartphones",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 8,
            label: "Tablets",
            href: "/réparations/tablets",
            description: null,
            locale: "fr",
            published_at: "2021-12-20T16:24:10.858Z",
            created_at: "2021-12-20T16:24:08.881Z",
            updated_at: "2022-03-30T08:54:30.790Z",
            main_href: "/repairs/tablets",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 10,
            label: "Ordinateurs",
            href: "/ordinateurs",
            description: "",
            locale: "fr",
            published_at: "2021-12-20T16:25:17.251Z",
            created_at: "2021-12-20T16:25:14.984Z",
            updated_at: "2022-01-28T09:56:18.239Z",
            main_href: "/computers",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
        ],
      },
      {
        __component: "menu.section",
        id: 7,
        label: "Réparations",
        links: [
          {
            id: 21,
            label: "Statut",
            href: "/statut",
            description: null,
            locale: "fr",
            published_at: "2021-12-20T17:02:48.563Z",
            created_at: "2021-12-20T17:02:46.768Z",
            updated_at: "2021-12-20T17:02:48.693Z",
            main_href: "/status",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 30,
            label: "Envoyer",
            href: "/envoyer",
            description: null,
            locale: "fr",
            published_at: "2021-12-23T14:12:41.470Z",
            created_at: "2021-12-23T14:12:39.773Z",
            updated_at: "2021-12-23T14:12:41.550Z",
            main_href: "/send-to-us",
            icon: null,
            showIsNewTag: null,
          },
          {
            id: 33,
            label: "Récupérer",
            href: "/récupérer",
            description: "",
            locale: "fr",
            published_at: "2021-12-23T14:14:26.938Z",
            created_at: "2021-12-23T14:14:25.037Z",
            updated_at: "2021-12-23T14:14:26.966Z",
            main_href: "/pickup",
            icon: null,
            showIsNewTag: null,
          },
          {
            id: 36,
            label: "Visitez nous",
            href: "/visitez-nous",
            description: "Visitez nous",
            locale: "fr",
            published_at: "2021-12-23T14:16:34.855Z",
            created_at: "2021-12-23T14:16:33.252Z",
            updated_at: "2021-12-23T14:16:34.876Z",
            main_href: "/visit-us",
            icon: null,
            showIsNewTag: null,
          },
          {
            id: 39,
            label: "Demander une réparation",
            href: "/checkout",
            description: null,
            locale: "fr",
            published_at: "2022-01-24T15:30:55.338Z",
            created_at: "2022-01-24T15:30:53.045Z",
            updated_at: "2022-02-21T15:45:51.622Z",
            main_href: "/checkout",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
        ],
      },
      {
        __component: "menu.section",
        id: 8,
        label: "Entreprise",
        links: [
          {
            id: 24,
            label: "Contact",
            href: "/contact",
            description: "Talk to us",
            locale: "fr",
            published_at: "2021-12-20T17:04:01.357Z",
            created_at: "2021-12-20T17:03:59.248Z",
            updated_at: "2021-12-20T17:04:01.378Z",
            main_href: "/contact",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 52,
            label: "B2B",
            href: "/b2b",
            description: null,
            locale: "fr",
            published_at: "2022-02-21T15:36:28.969Z",
            created_at: "2022-02-21T15:36:27.072Z",
            updated_at: "2022-02-21T15:36:29.075Z",
            main_href: "/b2b",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
        ],
      },
    ],
    localizations: [
      { id: 1, locale: "nl", published_at: "2021-12-10T14:39:30.271Z" },
      { id: 2, locale: "en", published_at: "2021-12-10T14:39:50.453Z" },
    ],
  },
  nl: {
    id: 1,
    locale: "nl",
    published_at: "2021-12-10T14:39:30.271Z",
    created_at: "2021-12-10T14:39:28.781Z",
    updated_at: "2022-04-05T19:52:18.425Z",
    footer: [
      {
        __component: "menu.section",
        id: 1,
        label: "Aanbod",
        links: [
          {
            id: 1,
            label: "Smartphones",
            href: "/herstellingen/smartphones",
            description: "Geef jouw tablet de beste behandeling",
            locale: "nl",
            published_at: "2021-12-10T14:35:45.828Z",
            created_at: "2021-12-10T14:35:24.698Z",
            updated_at: "2022-02-07T10:58:25.839Z",
            main_href: "/repairs/smartphones",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 4,
            label: "Tablets",
            href: "/herstellingen/tablets",
            description: "Geef jouw tablet de beste behandeling",
            locale: "nl",
            published_at: "2021-12-11T11:25:11.345Z",
            created_at: "2021-12-11T11:25:08.329Z",
            updated_at: "2022-02-07T10:57:18.337Z",
            main_href: "/repairs/tablets",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },

          {
            id: 6,
            label: "Computers",
            href: "/computers",
            description: "Wij helpen u uit de nood.",
            locale: "nl",
            published_at: "2021-12-11T12:30:07.479Z",
            created_at: "2021-12-11T11:45:46.705Z",
            updated_at: "2022-02-07T10:57:45.743Z",
            main_href: "/computers",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 47,
            label: "Game consoles",
            href: "/herstellingen/gameconsoles",
            description: null,
            locale: "nl",
            published_at: "2022-02-14T16:33:27.104Z",
            created_at: "2022-02-14T16:33:17.709Z",
            updated_at: "2022-03-05T16:40:36.950Z",
            main_href: "/repairs/gameconsoles",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
              "</svg>",
            showIsNewTag: true,
          },
        ],
      },
      {
        __component: "menu.section",
        id: 4,
        label: "Herstellen",
        links: [
          {
            id: 19,
            label: "Status bekijken",
            href: "/status",
            description: "Bekijk de status van uw herstelling",
            locale: "nl",
            published_at: "2021-12-20T17:02:07.866Z",
            created_at: "2021-12-20T17:02:06.282Z",
            updated_at: "2022-02-07T10:57:30.264Z",
            main_href: "/status",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 28,
            label: "Opsturen ",
            href: "/opsturen",
            description: "Stuur jouw toestel op",
            locale: "nl",
            published_at: "2021-12-23T14:11:46.091Z",
            created_at: "2021-12-23T14:11:43.754Z",
            updated_at: "2021-12-23T14:11:46.173Z",
            main_href: "/send-to-us",
            icon: null,
            showIsNewTag: null,
          },
          {
            id: 31,
            label: "Ophaaldienst",
            href: "/ophaaldienst",
            description: "Laat iFixers jouw toestel ophalen",
            locale: "nl",
            published_at: "2021-12-23T14:13:21.854Z",
            created_at: "2021-12-23T14:13:14.350Z",
            updated_at: "2022-02-19T16:05:29.078Z",
            main_href: "/pickup",
            icon: null,
            showIsNewTag: null,
          },
          {
            id: 34,
            label: "Kom langs",
            href: "/kom-langs",
            description: "Kom langs in onze winkel te Leuven",
            locale: "nl",
            published_at: "2021-12-23T14:15:19.442Z",
            created_at: "2021-12-23T14:15:17.778Z",
            updated_at: "2021-12-23T14:15:19.464Z",
            main_href: "/visit-us",
            icon: null,
            showIsNewTag: null,
          },
          {
            id: 37,
            label: "Herstelling aanmelden",
            href: "/checkout",
            description: null,
            locale: "nl",
            published_at: "2022-01-24T15:29:45.968Z",
            created_at: "2022-01-24T15:29:43.234Z",
            updated_at: "2022-04-05T15:14:37.234Z",
            main_href: "/checkout",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
        ],
      },
      {
        __component: "menu.section",
        id: 5,
        label: "Bedrijf",
        links: [
          {
            id: 22,
            label: "Contact",
            href: "/contact",
            description: "Neem contact met ons op",
            locale: "nl",
            published_at: "2021-12-20T17:03:29.748Z",
            created_at: "2021-12-20T17:03:28.051Z",
            updated_at: "2022-02-07T10:57:57.136Z",
            main_href: "/contact",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
          {
            id: 50,
            label: "B2B",
            href: "/b2b",
            description: null,
            locale: "nl",
            published_at: "2022-02-21T15:36:07.257Z",
            created_at: "2022-02-21T15:36:05.152Z",
            updated_at: "2022-02-21T15:36:07.356Z",
            main_href: "/b2b",
            icon:
              '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
              '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />\n' +
              "</svg>",
            showIsNewTag: null,
          },
        ],
      },
    ],
    localizations: [
      { id: 2, locale: "en", published_at: "2021-12-10T14:39:50.453Z" },
      { id: 3, locale: "fr", published_at: "2021-12-10T14:40:04.686Z" },
    ],
  },
};

export default data;
